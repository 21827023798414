<!--
 * @Author: your name
 * @Date: 2020-11-13 09:08:52
 * @LastEditTime: 2021-02-04 09:13:48
 * @LastEditors: yangliao
 * @Description: 支付结果页
 * @FilePath: /netHallOfficialAccounts/src/views/QueryPay/PaymentResult.vue
-->
<template>
  <div class="paymentResult">
    <div class="paymentResult-content">
      <div class="img"></div>
      <p class="info">付款成功啦！</p>
      <p class="otherInfo">我们为您提供全方位的服务，猜您想：</p>
    </div>
    <!-- 快捷支付 -->
    <div class="paymentResult-btns">
      <div class="btns">
        <!-- <van-button type="info" @click="gotoInvoice">开电子发票</van-button> -->
        <van-button type="default" @click="gotoHome">返回首页</van-button>
      </div>
    </div>
    <!-- 缴费记录 -->
    <!-- <div class="paymentResult-btns" v-else>
      <div class="block_btns">
        <van-button type="info" block @click="gotoMyRecord">查看我的记录</van-button>
      </div>
    </div> -->
    <!-- <div class="van-hairline--bottom"></div> -->
    <div v-if="!isCertified" class="paymentResult-other">
      <!-- 如果已经认证则不显示 -->
      <p>
        您还可以：
        <em @click="gotoCardCertified">
          认证
        </em>
        <!-- <em @click="gotoPreStorage">充值缴费</em> -->
        <!-- <em @click="gotoHome">返回首页</em> -->
      </p>
    </div>
  </div>
</template>

<script>
import storage from '@/utils/storage';
import store from '@/store';

export default {
  name: 'PaymentResult',
  data() {
    return {
      flag: true,
      isCertified: store.getters.homeInfo.isCertified
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.name === 'QueryPay') {
      this.flag = true;
    } else {
      this.flag = false;
    }
  },
  methods: {
    gotoInvoice() {
      this.$router.push({ path: '/Invoice' });
    },
    gotoMyWater() {
      this.$router.push({ path: '/WaterStatistics' });
    },
    gotoMyRecord() {
      this.$router.push({ path: '/WaterAccount/RechargeRecord' });
    },
    gotoPreStorage() {
      this.$router.push({ path: '/WaterAccount/PreStorage' });
    },
    gotoHome() {
      this.$router.push({ path: '/' });
    },
    gotoCardCertified() {
      this.$router.push({ name: 'CertifiedPage', path: '/CertifiedPage' });
    }
  }
};
</script>

<style lang="less" scoped>
.paymentResult {
  display: flex;
  flex-direction: column;
  background: #ffffff;

  .paymentResult-content {
    padding-top: 34px;
    .img {
      text-align: center;
      width: 127px;
      height: 100px;
      background: url('../../assets/background/paymentResult.png') no-repeat 0 0;
      background-size: cover;
      margin: 0 auto;
    }
    p.info {
      margin: 0px;
      text-align: center;
      font-family: PingFangSC-Medium;
      font-size: 16px;
      color: #303947;
      letter-spacing: 0;
      font-weight: 600;
      padding-top: 10px;
    }
    p.otherInfo {
      margin: 0px;
      margin-top: 24px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #9d9d9d;
      padding-left: 16px;
    }
  }

  .paymentResult-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 21px;
    padding-bottom: 21px;
    .btns {
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      .van-button {
        border-radius: 4px;
        width: 100%;
        // width: calc(100% / 2 - 10px);
      }
      // .van-button:last-child {
      //   margin-left: 10px;
      // }
      .van-button--default {
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .block_btns {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
      .van-button {
        border-radius: 4px;
      }
    }
  }

  .paymentResult-other {
    // padding-top: 15px;
    // padding-bottom: 250px;
    padding-left: 16px;
    p {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #9d9d9d;
      letter-spacing: -0.42px;
      em {
        font-style: normal;
        padding: 0px 10px 0px 10px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #346ac3;
        letter-spacing: -0.42px;
        line-height: 20px;
      }
    }
  }
}
</style>
